<template>
    <div class="whiteColor">
                        <div class="person_satisfy_line">
                            <div class="satisfy_gary_txt textLeft">
                                您的满意度：
                            </div>
                            <ul class="person_satisfy_ul">
                                <li @click="chooseTemporary('total_serve_level',3)" :class="{active : commentinfo.total_serve_level === 3}">
                                    不满意
                                    <div class="ar_ico"></div>
                                </li>
                                <li @click="chooseTemporary('total_serve_level',2)"  :class="{active : commentinfo.total_serve_level === 2}">
                                    基本满意
                                    <div class="ar_ico"></div>
                                </li>
                                <li @click="chooseTemporary('total_serve_level',1)" :class="{active : commentinfo.total_serve_level === 1}">
                                    满&nbsp; &nbsp;意
                                    <div class="ar_ico"></div>
                                </li>
                            </ul>
                        </div>
                        <div class="list_satisfy_line" :class="{show : commentinfo.total_serve_level > 1}">
                            <div class="list_satisfy_li" v-for="item in itemdata" :key="item.key">
                                <div class="satisfy_gary_txt">
                                    {{item.name}}
                                </div>
                                <ul class="list_satisfy_ul">
                                    <li @click="chooseTemporary(item.key,value.key)" v-for="value in itemvalue" :key="value.key" :class="{active : commentinfo[item.key] === value.key}">
                                        {{value.name}}
                                    </li>
                                </ul>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="other_info">
                            <div class="satisfy_gary_txt">
                                其他意见建议：
                            </div>
                            <div class="other_info_ta">
                                <textarea  v-model="commentinfo.assess_desc" @change="updesc"  name="" id="" cols="30" rows="10" class="other_info_textarea ng-pristine ng-valid"  placeholder="针对服务的态度、效率、环境、能力、廉洁等方面的具体意见建议"></textarea>
                            </div>
                        </div>
                    </div>
</template>
<script>
export default {
  name: 'Comment',
  data () {
    var itemdata = [
      { key: 'attitude_serve_level', name: '服务态度' },
      { key: 'efficiency_serve_level', name: '效率' },
      { key: 'environment_serve_level', name: '环境' },
      { key: 'power_serve_level', name: '能力' },
      { key: 'honest_serve_level', name: '廉洁' }
    ]
    var itemvalue = [
      { key: 3, name: '不满意' },
      { key: 2, name: '基本满意' },
      { key: 1, name: '满意' }
    ]
    return {
      itemdata: itemdata,
      itemvalue: itemvalue
    }
  },
  props: [
    'commentinfo'
  ],
  methods: {
    chooseTemporary (key, value) {
      this.commentinfo[key] = value
      if (key === 'total_serve_level') {
        this.commentinfo.attitude_serve_level = '1'
        this.commentinfo.efficiency_serve_level = '1'
        this.commentinfo.environment_serve_level = '1'
        this.commentinfo.power_serve_level = '1'
        this.commentinfo.honest_serve_level = '1'
      }
      this.commentinfo.ischeck = 1
      this.$emit('change', this.commentinfo)
    },
    updesc () {
      this.$emit('change', this.commentinfo)
    }
  }
}
</script>
<style scoped src="../../assets/css/evaluation.css"></style>
