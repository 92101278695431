<template>
<Page title="满意度评价">
  <div>
    <article id="page-assistance">
      <commonbanner></commonbanner>
      <div class="container"  v-if="showmes === 1">
        <div class="row">
          <div class="officeComment">
            <div class="subtitle"><h2>请对机关履职情况进行满意度评价</h2><hr class="underline"></div>
            <div class="satisfy_assess_con">
              <div class="select_posi">
                <div class="satisfy_gary_txt">选择评价部门:</div>
                <ul class="select_li">
                  <li>
                    <Collection  name="city"  v-slot="{ collection }" >
                            <a-select  placeholder="市区" @change="handleChange($event, collection)">
                               <a-select-option value=''>请选择</a-select-option>
                              <a-select-option v-for="value in collection" :key="value.district_id"  :value="value.district_id">
                               {{value.district_name}}
                              </a-select-option>
                            </a-select>
                            </Collection>
                  </li>
                  <li>
                     <Collection  name="area"    v-slot="{ collection }" :filter="{ parent: citykey }" >
                            <a-select  placeholder="县"  @change="areaChange($event, collection)">
                               <a-select-option value=''>请选择</a-select-option>
                              <a-select-option v-for="value in collection" :key="value.district_id" :value="value.district_id">
                               {{value.district_name}}
                              </a-select-option>
                            </a-select>
                            </Collection>
                  </li>
                  <li>
                     <Collection
                name="provider"
                :page="provider.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  provider_type: providerQuery.provider_type
                }"
                v-slot="{ collection }"
              ><a-select  placeholder="机关"  @change="proChange($event, collection)">
                               <a-select-option value=''>请选择</a-select-option>
                              <a-select-option v-for="value in collection" :key="value.provider_id" :value="value.provider_id">
                               {{value.provider_name}}
                              </a-select-option>
                            </a-select>
                     </Collection>
                  </li>
                </ul>
              </div>
              <Commentpro :commentinfop=firstinfo @change="CommentChange"></Commentpro>
            </div>
          </div>
          <div class="officeComment">
            <div class="subtitle"><h2>请对以下工作进行满意度评价</h2><hr class="underline"></div>
            <div class="satisfy_assess_con">
              <ul class="officeIobList clearFloat">
                <li v-for="item in workitem" :key="item.key"
                 @click="changeWorkType(item.name)"
                  :class="[{over : item.over === 1 },{active : checktype === item.name}]"
                >
                  <div>
                    <span class="ok_word"></span>
                    <i :class="'officeIcon' + item.key"></i>
                    <h2><span>{{item.name}}</span></h2>
                  </div>
                </li>
              </ul>
              <div class="officeIobComment">
                <Comment :commentinfo=commentinfo @change="CommentChange"></Comment>
              </div>
            </div>
            <div class="satisfy_assess_foot">
            <a href="javascript:;" class="satisfy_assess_foot_a first active" @click="subfunction();">提交</a>
            <a href="javascript:;" class="satisfy_assess_foot_a last" @click="reset('/homepage/homepage');">取消</a>
            <div class="satisfy_assess_foot_red">
                说明：请客观提出满意评价，并自觉遵守国家有关法律法规，不得发表违法和不适当的言论
            </div>
        </div>
          </div>
        </div>
      </div>
      <div class="msgbox" v-if="showmes === 2">
      <a-result
    status="success"
    :title="msg.title"
    :sub-title="msg.desc"
  >
    <template #extra>
      <a-button  @click="userinfo" type="primary">
        返回首页
      </a-button>
    </template>
  </a-result>
    </div>
    </article>
  </div>
</Page>
</template>
<script>
import Commonbanner from '@/components/base/Commonbanner'
import Collection from '@/components/Collection'
import Comment from '@/components/business/Comment'
import Commentpro from '@/components/business/Commentpro'
import model from '../../core/model.js'
export default {
  name: 'evaluationFrom',
  mixins: [model],
  components: {
    Commonbanner,
    Collection,
    Comment,
    Commentpro
  },
  data () {
    var firstinfo = {
      total_serve_level: '',
      attitude_serve_level: '',
      efficiency_serve_level: '',
      environment_serve_level: '',
      power_serve_level: '',
      honest_serve_level: '',
      host_id: '',
      assess_type: '司法机关',
      assess_desc: ''
    }
    var workitem = [
      { name: '人民调解', key: 1 },
      { name: '法律援助', key: 2 },
      { name: '公证服务', key: 3 },
      { name: '司法鉴定', key: 4 },
      { name: '仲裁服务', key: 5 },
      { name: '律师服务', key: 6 },
      { name: '国家统一法律职业资格考试', key: 7 },
      { name: '普法宣传', key: 8 }
    ]
    var subcomment = {}
    subcomment = firstinfo
    subcomment.sub_assess = []
    var msg = {
      title: '提交成功',
      desc: '您的评价信息已经提交成功'
    }
    return {
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      citykey: '',
      msg: msg,
      area: '',
      checktype: '',
      commentinfo: {},
      showmes: 1,
      firstinfo: firstinfo,
      subcomment: subcomment,
      workitem: workitem,
      providerQuery: {
        city: '',
        area: '',
        provider_type: '司法机关'
      }
    }
  },
  methods: {
    changeWorkType (value) {
      this.checktype = value
      // 提交数据组装
      var cinfo = this.commentinfo
      if (cinfo.ischeck) {
        this.subdata(this.commentinfo)
      }
      // 类型机构获取
      this.getcommentinfo(value)
    },
    userinfo () {
      this.$router.push('/')
    },
    getcommentinfo (value) {
      var commentinfo = {
        total_serve_level: '',
        attitude_serve_level: '',
        efficiency_serve_level: '',
        environment_serve_level: '',
        power_serve_level: '',
        honest_serve_level: '',
        assess_type: value,
        assess_desc: '',
        ischeck: ''
      }
      if (this.subcomment.sub_assess.length > 0) {
        var info = this.subcomment.sub_assess.find((item) => {
          return item.assess_type === value
        })
        if (typeof (info) !== 'undefined') {
          commentinfo = info
        }
      } else {
        commentinfo.assess_type = value
      }
      this.commentinfo = commentinfo
    },
    subdata (commentinfo) {
      var subdataarr = this.subcomment.sub_assess
      var that = this
      if (subdataarr.length > 0) {
        var index = 0
        subdataarr.forEach(function (item, index) {
          if (item.assess_type === commentinfo.assess_type) {
            that.subcomment.sub_assess[index] = commentinfo
          } else {
            index++
          }
        })
        if (subdataarr.length >= index) {
          that.subcomment.sub_assess.push(commentinfo)
        }
      } else {
        that.subcomment.sub_assess.push(commentinfo)
      }
    },
    handleChange (value, options) {
      if (value === '') {
        this.providerQuery.city = ''
        return false
      }
      this.citykey = value
      var obj = {}
      obj = options.find((item) => {
        return item.district_id === value
      })
      this.providerQuery.city = obj.district_name
    },
    CommentChange (commentinfo) {
      if (commentinfo.assess_type === '司法机关') {
        // 司法机关数据处理
        commentinfo.sub_assess = this.subcomment.sub_assess
        this.firstinfo = commentinfo
        // var sub_assessarr = this.subcomment.sub_assess
        this.subcomment = commentinfo
        // this.subcomment.sub_assess = sub_assessarr
      } else {
        this.commentinfo = commentinfo
        var workitem = this.workitem
        workitem.forEach(function (item, index) {
          if (item.name === commentinfo.assess_type) {
            workitem[index].over = 1
          }
        })
        this.workitem = workitem
      }
    },
    subfunction () {
      var cinfo = this.commentinfo
      if (cinfo.ischeck) {
        this.subdata(this.commentinfo)
      }
      var commentinfo = this.subcomment
      debugger
      if (commentinfo.total_serve_level === '') {
        this.$message.error('选择满意度后提交')
        return false
      }
      if (commentinfo.host_id === '') {
        this.$message.error('选择部门后评价')
        return false
      }
      debugger
      var that = this
      var result = that.saveData({ url: 'assess', data: this.subcomment })
      if (result) {
        that.showmes = 2
      }
      console.log(this.subcomment)
    },
    areaChange (value, options) {
      if (value === '') {
        this.providerQuery.area = ''
        return false
      }
      var obj = {}
      obj = options.find((item) => {
        return item.district_id === value
      })
      this.providerQuery.area = obj.district_name
    },
    proChange (value, options) {
      this.subcomment.host_id = value
      this.subcomment.host_type = 'prodiver'
    }
  }
}
</script>
<style scoped src="../../assets/css/evaluation.css"></style>
